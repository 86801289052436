.my-reports {
  padding: 20px;
  background-color: #0e0e0e;
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Sleek font family */
}

.job-card {
  background-color: rgba(0, 64, 128, 0.8); /* Glassy blue effect */
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  transition: all 0.3s ease; /* Smooth transition for hover and expansion */
}

.job-card:hover {
  background-color: rgba(0, 64, 128, 1); /* Darker blue on hover */
  transform: translateY(-2px); /* Slight lift on hover */
}

.job-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.job-details {
  margin-top: 10px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out; /* Smooth transition for dropdown */
}

.job-card.expanded .job-details {
  max-height: 500px; /* Allows enough height for content */
  padding: 10px 0; /* Adds padding when expanded */
}

.job-report-frame {
  width: 100%;
  height: 500px;
  border: none;
  background-color: white;
  margin-top: 10px;
  border-radius: 8px; /* Rounded corners for iframe */
}

.loading-icon {
  margin-left: 10px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
