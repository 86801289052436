/* PremiumModulesSection.css */

.premium-section {
  background-color: #0e0e0e; /* Dark background for consistency */
  color: #ffffff;
  padding: 80px 20px;
  text-align: center;
}

.premium-title {
  max-width: 800px;
  margin: 0 auto 40px;
}

.premium-title h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #ffffff;
}

.premium-title .highlight {
  color: #ffd700; /* Yellow highlight for "Premium" */
}

.premium-title p {
  font-size: 1.2rem;
  color: #e0e0e0;
}

.premium-modules {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.module {
  background: #1a1a1a; /* Slightly lighter dark background for modules */
  border: 1px solid #ffd700; /* Yellow border for emphasis */
  border-radius: 10px;
  padding: 30px;
  max-width: 600px;
  width: 100%;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.module:hover {
  transform: translateY(-5px); /* Hover effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.module-content h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #ffd700; /* Yellow color for module headings */
}

.module-content p {
  font-size: 1rem;
  color: #e0e0e0;
  line-height: 1.6;
}
