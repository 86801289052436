/* Background styling */
.login-page {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  height: 100vh; /* Full height of the viewport */
  background-color: #0e0e0e; /* Dark background color */
  color: #fff; /* White text color for contrast */
  overflow: hidden; /* Prevents overflow if content is too large */
  position: relative;
}

/* Login card styling */
.login-card {
  background: rgba(255, 255, 255, 0.1); /* Glassy effect background */
  backdrop-filter: blur(10px); /* Blur effect for glassy appearance */
  padding: 40px;
  border-radius: 16px;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 350px; /* Increased width for a more spacious look */
  width: 100%;
  display: flex; /* Ensures flexbox alignment within the card */
  flex-direction: column; /* Stacks children vertically */
  align-items: center; /* Centers all children horizontally */
  gap: 20px; /* Adds spacing between elements */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Light border for additional contrast */
}

/* Logo styling */
.login-logo {
  width: 80px; /* Increased size for better visibility */
  margin-bottom: 20px;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.5)); /* Shadow for better contrast */
}

/* Headline styling */
.login-card h1 {
  font-size: 28px; /* Slightly larger font size */
  margin-bottom: 10px;
  color: #FFD700; /* Bright yellow color for emphasis */
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.75); /* Adds a shadow for depth */
}

/* Description styling */
.login-card p {
  font-size: 14px;
  color: #b8b8b8;
  margin-bottom: 20px;
  text-align: center; /* Centers text */
}

/* Discord button styling */
.discord-button {
  background-color: #5865F2; /* Discord blue color */
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s; /* Added smooth transition for hover and scale */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds subtle shadow for depth */
}

/* Hover effect for the button */
.discord-button:hover {
  background-color: #4752c4;
  transform: scale(1.05); /* Slight scaling effect on hover */
}

/* Discord icon styling */
.discord-button img {
  width: 24px; /* Increased icon size */
  height: 24px;
}
