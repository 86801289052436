.labels-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: rgba(20, 20, 20, 0.9); /* Darker glassy background */
  border-radius: 8px;
  font-size: 14px;
  max-width: 500px; /* Limits the width to keep it centered and contained */
  width: 100%;
  backdrop-filter: blur(6px); /* Subtle blur effect for a glassy look */
  border: 1px solid rgba(255, 255, 255, 0.1); /* Light border for separation */
  margin: 0 auto 20px auto; /* Centers the section horizontally */
}

.label-entry {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%; /* Full width to align with the container */
}

.label-entry input {
  padding: 8px; /* Increased padding for better UX */
  margin-right: 10px; /* Increased margin for more spacing */
  border: 1px solid #333;
  border-radius: 4px;
  background-color: #111; /* Darker input field for contrast */
  color: white;
  flex: 1;
  transition: border-color 0.3s ease; /* Smooth transition for border color */
}

.label-entry input:focus {
  border-color: #5865F2; /* Highlight border on focus */
  outline: none; /* Removes default outline */
}

.icon {
  cursor: pointer;
  margin-left: 8px; /* Slightly more space for better separation */
  color: #888;
  transition: color 0.3s ease; /* Smooth color transition on hover */
}

.icon:hover {
  color: #ffffff; /* Change to white on hover for better visibility */
}

.add-label-button {
  background-color: #5865F2;
  color: white;
  border: none;
  padding: 8px 16px; /* Increased padding for a more balanced button size */
  border-radius: 20px;
  cursor: pointer;
  margin-top: 15px; /* Increased margin for better spacing */
  display: flex;
  align-items: center;
  gap: 8px; /* Slightly more space between icon and text */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition for hover effect */
}

.add-label-button:hover {
  background-color: #4752c4;
  transform: translateY(-2px); /* Adds a subtle lift effect on hover */
}
