.info-section {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 20px;
  text-align: center;
  overflow: hidden; /* Ensures the video is contained within the section */
}

.info-section::before,
.info-section::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 150px; /* Increased height for more intense blur */
  z-index: 1;
  pointer-events: none;
}

.info-section::before {
  top: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), transparent); /* Increased opacity for stronger blur */
}

.info-section::after {
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent); /* Increased opacity for stronger blur */
}

.info-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.info-title {
  position: relative;
  z-index: 2; /* Above the video */
  color: #ffffff;
  margin-bottom: 30px;
}

.info-title .highlight {
  color: #FFD700; /* Gold color for the highlight */
}

.info-cards {
  position: relative;
  z-index: 2; /* Above the video */
  display: flex;
  gap: 30px;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1200px;
}

.info-card {
  background-color: rgba(255, 255, 255, 0.1); /* Transparent white background */
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  margin: 10px;
  color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.info-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.info-icon {
  margin-bottom: 20px;
}
