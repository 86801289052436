.analyze-page {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px;
  background-color: #0b0b0b; /* Darker background color for the Analyze Page */
  color: white;
}

.modules,
.settings,
.target-wallets,
.my-tasks,
.starting-wallet {
  padding: 10px;
  background-color: rgba(20, 20, 20, 0.9); /* Darker glassy background */
  border-radius: 8px;
  font-size: 14px;
  backdrop-filter: blur(6px); /* Subtle blur effect */
  border: 1px solid rgba(255, 255, 255, 0.1); /* Light border for separation */
}

.inline-field {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.input-field-small {
  width: 150px;
  padding: 5px;
  margin: 0;
  border: 1px solid #333;
  border-radius: 4px;
  background-color: #111; /* Darker input field */
  color: white;
}

.radio-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.wallet {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.wallet input {
  flex: 1;
  width: 350px;
  padding: 5px;
  margin-right: 5px;
  border: 1px solid #333;
  border-radius: 4px;
  background-color: #111; /* Darker input field */
  color: white;
}

.icon {
  cursor: pointer;
  margin-left: 5px;
  color: #888;
}

.target-wallets {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.add-wallet-button {
  background-color: #5865F2;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.add-wallet-button:hover {
  background-color: #4752c4;
}

.start-button {
  grid-column: 1 / -1;
  background-color: #5865F2;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 10px;
  width: 150px;
}

.start-button:disabled {
  background-color: #333;
  cursor: not-allowed;
}

.my-tasks {
  grid-column: 1 / -1;
  margin-top: 20px;
}

.task-card {
  background-color: rgba(30, 30, 30, 0.9); /* Darker glassy effect */
  padding: 15px;
  margin: 10px 0;
  border-radius: 8px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.1); /* Subtle border */
}

.task-card .job-id {
  font-weight: bold;
}

.task-card .job-status {
  display: flex;
  align-items: center;
}

.loading-icon {
  margin-left: 10px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.starting-wallet input {
  width: 95%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #333;
  background-color: #111; /* Darker input field */
  color: white;
  margin-top: 10px;
}
