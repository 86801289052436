.hero {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.hero::before,
.hero::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 150px;
  z-index: 1;
  pointer-events: none;
}

.hero::before {
  top: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), transparent);
}

.hero::after {
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.hero-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
  z-index: 2;
}

.hero-logo {
  width: 150px; /* Adjust this value based on your logo size */
  height: auto;
  margin-bottom: 2rem;
}

.hero-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.75);
}

.hero-subtitle {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.75);
}

.highlight {
  color: #FFD700;
}

.hero-icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-top: 2rem;
  background-color: white;
  color: black;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.hero-icon-button:hover {
  background-color: rgba(255, 255, 255, 0.85);
}