/* Navbar.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem; /* Increase padding for more space from the edges */
  background: transparent; /* Transparent background */
  position: absolute;
  width: 95%;
  z-index: 1000; /* Ensures navbar is on top */
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
  color: white; /* White color for branding */
}

.login-button {
  padding: 0.5rem 1.5rem;
  background-color: white; /* White background for button */
  color: black; /* Black text for button */
  border: 2px solid black; /* Black border */
  border-radius: 30px; /* Rounded corners */
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for hover effect */
  margin-left: auto; /* Pushes the button to the right */
}

.login-button:hover {
  background-color: black; /* Hover effect: black background */
  color: white; /* Hover effect: white text */
}
