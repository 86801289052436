/* Main container styling */
.dashboard {
  display: flex;
  height: 100vh;
  background-color: #0b0b0b; /* Darker background color */
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Sleek font family */
}

/* Sidebar styling */
.sidebar {
  background-color: rgba(20, 20, 20, 0.9); /* Glassy dark effect */
  width: 250px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width 0.3s ease, padding 0.3s ease; /* Smooth transition for width and padding */
  border-right: 1px solid rgba(255, 255, 255, 0.1); /* Subtle border on the right */
  backdrop-filter: blur(10px); /* Glassy blur effect */
}

.sidebar.collapsed {
  width: 60px; /* Reduced width when collapsed */
  padding: 10px 0; /* Adjust padding when collapsed */
}

/* Logo styling for sidebar */
.sidebar-logo {
  margin-bottom: 20px;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-logo img {
  width: 100%;
  height: auto;
}

/* User info styling */
.user-info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; /* Stack avatar and text vertically */
  margin-bottom: 20px; /* Adds space below the user info */
}

.user-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px; /* Adds space below the avatar */
}

.avatar-img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Prevents the image from being cut off */
  border-radius: 50%;
}

/* User details styling */
.user-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
}

.user-details h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.user-details p {
  margin: 0;
  font-size: 12px;
  color: #32cd32; /* Online green color */
}

/* Menu items styling */
.menu {
  list-style: none;
  padding: 0;
  margin: 10px 0; /* Reduced margin for better alignment */
  width: 100%;
  text-align: center; /* Centers text in the menu */
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the icon horizontally */
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s, padding 0.3s, width 0.3s; /* Smooth transition */
  border-radius: 5px; /* Slightly rounded edges */
  margin-bottom: 10px; /* Adds spacing between menu items */
  color: white;
  font-size: 14px;
}

.menu-item:hover,
.menu-item.active {
  background-color: rgba(50, 50, 50, 0.8); /* Highlight active or hovered tab with a darker glassy effect */
  border-radius: 4px; /* Keep indicator within the sidebar size */
}

.menu-item span {
  margin-left: 10px;
  display: inline-block; /* Make it inline-block for collapsing */
  opacity: 1; /* Visible by default */
  transition: opacity 0.3s ease; /* Smooth transition for text opacity */
}

.sidebar.collapsed .menu-item {
  justify-content: center; /* Center the icon in collapsed mode */
  width: 100%; /* Full width for centering */
  padding: 8px 0; /* Adjust padding for slimmer sidebar */
}

.sidebar.collapsed .menu-item span {
  opacity: 0; /* Hide the text when sidebar is collapsed */
  display: none; /* Prevents overflow or content shifting */
}

.sidebar.collapsed .menu-item.active {
  background-color: rgba(50, 50, 50, 0.8); /* Keep the active background color */
  width: 50px; /* Adjust size of the active indicator */
  border-radius: 4px; /* Ensure it's within the width of the collapsed sidebar */
}

/* Toggle button styling */
.toggle-button {
  margin-top: auto;
  background-color: rgba(50, 50, 50, 0.8); /* Darker, glassy effect */
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%; /* Makes the button circular */
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.toggle-button:hover {
  background-color: rgba(70, 70, 70, 0.8); /* Slightly lighter on hover */
}

/* Main content area styling */
.main-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto; /* Allows scrolling if content is tall */
  background-color: #0b0b0b; /* Darker background color for content area */
}
